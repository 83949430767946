// We name it price and not item or product to be consistent with Stripe
const prices = [
  {
    id: process.env.GATSBY_PRICE_1,
    name: 'For one year',
    textOne: 'Save 17% ',
    textTwo: ' with the One Year Pass',
    amount: 117,
  },
  {
    id: process.env.GATSBY_PRICE_2,
    name: 'For 6 months',
    textOne: 'Get started',
    textTwo: ' with the 6 months Pass',
    amount: 89,
  },
];
export default prices;
