import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

const FormFieldContainer = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  border-top: 1px solid ${themeGet('colors.uiBlueLighter', '#4517ff')};

  &:first-of-type {
    border-top: none;
  }
`;

const Label = styled.label`
  width: 20%;
  min-width: 100px;
  padding: 15px;
  color: #ffffff;
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: 1px solid ${themeGet('colors.uiBlueLighter', '#4517ff')};
  border-radius: 4px 0 0 4px;
  background-color: ${themeGet('colors.uiWhiteBlueish', '#27E8A2')};
  color: ${themeGet('colors.uiBlueLight', '#4517ff')};
`;

const Input = styled.input`
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 15px;
  color: #29225e;
  animation: 1ms void-animation-out;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 49px;

  &::placeholder {
    color: ${themeGet('colors.uiBlueLight', '#4517ff')};
  }
`;

const FormField = ({
  label,
  type,
  name,
  placeholder,
  auth,
  required,
  onChange,
}) => {
  return (
    <FormFieldContainer>
      <Label htmlFor={name}>{label}</Label>
      <Input
        name={name}
        type={type}
        defaultValue={auth}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      />
    </FormFieldContainer>
  );
};

export default FormField;
