import styled from 'styled-components';
import { themeGet } from 'styled-system';

const Row = styled.div`
  width: 475px;
  margin: 30px auto;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);

  button {
    width: 100%;
    border-radius: 4px;
  }

  @media (max-width: 550px) {
    width: 100%;
  }

  &.rowCard {
    margin: 45px auto;
    background-color: transparent;
  }

  &.rowCoupon {
    margin-bottom: 1rem;
    border-right-color: ${themeGet('colors.uiBlue', '#4517ff')};

    label {
      background-color: ${themeGet('colors.uiWhiteBlueish', '#27E8A2')};
      color: ${themeGet('colors.uiBlueLight', '#4517ff')};
    }
  }

  &.rowCouponButton {
    background-color: transparent;
    box-shadow: none;
  }

  &.rowSubmitButton {
    background-color: transparent;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  }
`;

export default Row;
