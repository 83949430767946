import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Row from '../../../components/Row';
import FormField from '../../../components/FormField';
import Container from 'common/src/components/UI/Container';
import { Auth, API } from 'aws-amplify';
import Image from 'common/src/components/Image';
import stripeImage from 'common/src/assets/image/stripe-secured-pay.png';
import Alertantd from 'common/src/components/Alertantd/Alertantd';
import Loader from 'common/src/components/Loader';

import BlockWrapper, {
  CardElementContainer,
  SubmitButton,
  TextContent,
  TextContainer,
} from './stripe.style';
import {
  setUser,
  getUser,
  isLoggedIn,
  checkUserCognito,
  getStripeUser,
} from '../../../utils/auth';
import {
  setStripeCouponId,
  getStripeCouponId,
  setStripeCoupon,
  getStripeCoupon,
  getStripePriceAmount,
  getStripePriceId,
} from '../../../utils/stripe';
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import prices from './options'; // replace prices and price
import { fontSize } from 'styled-system';

// TODO : Clean this to use Stripe API or at least only Data index.json - for now we have a duplicate - 1 value in ./options and 1 in data/index.json
prices[0] = getStripePriceAmount();

console.table('prices', prices);

const PricingSection = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  secText,
}) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [couponError, setCouponError] = useState();
  const [couponSuccess, setCouponSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [cardNumberComplete, setcardNumberComplete] = useState(false);
  const [displayCouponField, setDisplayCouponField] = useState(false);
  const [couponIDFieldValue, setcouponIDFieldValue] = useState(false);
  const [priceDiscountedRatio, setPriceDiscountedRatio] = useState(1);


  // The text that dispaly the price
  // Ex: For --$117-- $58
  const priceTitle = data => {
    if (priceDiscountedRatio === 1) {
      return data.priceTag1 + ' $' + getStripePriceAmount() + data.priceTag2;
    } else {
      return [
        data.priceTag1,
        ' ',
        <strike> {' $' + getStripePriceAmount()} </strike>,
        ' ',
        ' $' +
          Math.trunc(getStripePriceAmount() * priceDiscountedRatio) +
          data.priceTag2,
      ];
    }
  };
  // TIP
  // use the cardElements onChange prop to add a handler
  // for setting any errors:
  const handleCheckCoupon = async () => {
    if (!couponIDFieldValue) {
      return;
    }

    const body = {
      couponID: couponIDFieldValue,
    };

    setStripeCouponId(couponIDFieldValue);

    console.log(body);

    const apiName = 'payment';
    const path = '/payment/applyCoupon';
    const myInit = {
      // OPTIONAL
      body: body, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };

    try {
      setCouponError(false);
      const res = await API.post(apiName, path, myInit);

      if (res && res.coupon && res.coupon.valid && res.coupon.percent_off) {
        if (!res.coupon.times_redeemed || res.coupon.times_redeemed > 0) {
          setPriceDiscountedRatio((100 - res.coupon.percent_off) / 100);
          setCouponSuccess(
            'Your coupon ' +
              couponIDFieldValue +
              ' has been applied and saves you ' +
              res.coupon.percent_off +
              '%!'
          );
          setStripeCoupon(res.coupon);
        }
      } else {
        setCouponError(res.message);
      }
    } catch (error) {
      const msg =
        'Mmmm... It seems that the coupon code "' +
        couponIDFieldValue +
        '" does not exist or is no longer valid. Maybe it has already been used, is outdated or it is simply mispelled. Please double check the spelling and try again. Contact us if needed, we are here to help.';

      console.error(error, msg);
      setCouponError(msg);
    }
  };

  const handleCardDetailsChange = ev => {
    setcardNumberComplete(ev.complete);
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    const stripeUser = getStripeUser();

    setProcessingTo(true);

    const cardElement = elements.getElement('card');

    const billingDetails = {
      name: stripeUser.userAttributes.name,
      email: stripeUser.userAttributes.email,
    };

    let paymentMethodReq;

    try {
      paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      console.log('paymentMethodReq', paymentMethodReq);

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }
    } catch (error) {
      setCheckoutError(error.message);
      setProcessingTo(false);
    }

    const body = {
      priceId: getStripePriceId(),
      email: billingDetails.email,
      name: billingDetails.name,
      user: {
        name: stripeUser.userAttributes.name,
        email: stripeUser.userAttributes.email,
        family_name: stripeUser.userAttributes.family_name,
        given_name: stripeUser.userAttributes.given_name,
        userPoolId: stripeUser.userPoolId,
        clientId: stripeUser.clientId,
        sub: stripeUser.userAttributes.sub,
        username: stripeUser.username,
      },
      couponID: couponIDFieldValue,
      paymentMethod: paymentMethodReq.paymentMethod.id,
      // couponIDField: ev.target.coupon.value,
    };

    // console.table('body', body.user);
    // console.table('stripeUser', stripeUser);
    // console.table('stripeUser.userAttributes', stripeUser.userAttributes);
    // debugger;

    const apiName = 'payment';
    const path = '/payment/checkoutAddUserToGroup';
    const myInit = {
      // OPTIONAL
      body: body, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };

    try {
      const res = await API.post(apiName, path, myInit);
      // dispatch(authAction.onPaymentSuccess());
      console.log('res', res);
      
      // setProcessingTo(false);
      
      if (res.statusCode === 200) {
        if (res.payment_intent_client_secret) {
          try {
            const paymentIntent = await stripe.confirmCardPayment(
              res.payment_intent_client_secret,
              {
                payment_method: {
                  card: cardElement,
                  billing_details: billingDetails,
                },
              }
            );

            console.log(paymentIntent);

            if (
              paymentIntent
              && paymentIntent.paymentIntent
              && paymentIntent.paymentIntent.status === 'succeeded') {
              window.location.href = '/welcome';
            } else {
              setProcessingTo(false);
              setInvoiceUrl(res.hosted_invoice_url);
            }
          } catch (err) {
            // Handle result.error or result.paymentIntent
            console.log('err', err);
            console.log(
              'err.error.payment_intent.status',
              err.error.payment_intent.status
            );

            if (
              err.error &&
              err.error.payment_intent &&
              err.error.payment_intent.status === 'succeeded'
            ) {
              window.location.href = '/welcome';
            }
          }
        } else {
          window.location.href = '/welcome';
        }
      }
    } catch (error) {
      console.log('error', error);
      setCheckoutError(error.message);
    }
  };

  const iframeStyles = {
    base: {
      color: '#4E4E82	',
      fontSize: '16px',
      height: '24px',
      iconColor: '#4E4E82',
      '::placeholder': {
        color: '#9c9cb7',
      },
    },
    invalid: {
      iconColor: '#aa14ce',
      color: '#aa14ce',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true,
  };

  const Data = useStaticQuery(graphql`
    query {
      charityJson {
        stripe {
          title
          subtitle
          slogan
          recurringCharge
          priceTag1
          priceTag2
        }
      }
    }
  `);

  // const [state, setState] = useState({});

  const data = Data.charityJson.stripe;

  return (
    <>
      {!invoiceUrl && (<form onSubmit={handleFormSubmit} width="100%">
      <Box {...sectionWrapper} id="pricing_section">
        <BlockWrapper>
          <Container>
            <Box {...secTitleWrapper}>
              <header>
                <Heading as="h5" {...secText} content={data.slogan} />
                {isLoggedIn && (
                  <Heading
                    as="h3"
                    {...secHeading}
                    content="Welcome! You have successfully signed up!"
                  />
                )}
                <h2 id="header">{priceTitle(data)}</h2>
                <Heading as="h3" {...secHeading} content={data.title} />
                <Heading
                  as="h6"
                  className="extra-small"
                  {...secHeading}
                  content={data.recurringCharge}
                />
                {/* {couponSuccess && (
                  <Heading
                    as="h3"
                    style={{ fontSize: '1.1rem' }}
                    {...secHeading}
                    content={couponSuccess}
                  />
                )} */}
                {/* <Heading as="h6" {...secHeading} content={data.recurringCharge} /> */}
              </header>
              
              {displayCouponField && couponError && (
                <Row>
                  <Alertantd message={couponError} type="error" />
                </Row>
              )}
              
              {displayCouponField && couponSuccess && (
                <Row className="rowCoupon rowCouponSuccess">
                  <Alertantd message={couponSuccess} type="success" />
                </Row>
              )}

            </Box>
            <Box>

              {isProcessing && (
                <div className="stripeLoaderWrapper">
                  <Loader loaderColor="#4517ff" className="stripeLoader" />

                  <Box className="stripeLoaderInner">
                    <h3>Stripe is contacting your bank</h3>
                    <h4>Sometimes it takes up to 10 seconds! I know...</h4>
                    <h4>I think we have time for a little chit chat. So I'll share one of my favorite quote. Ready ? Here you go:</h4>
                    <h5>Fear doesn't prevent danger</h5>
                    <h4>I got it from my dad. It's pretty obvious, right? But so much worth remembering! Now I have just enough time to wish you a beautiful day. I'll see you on the next screen.</h4>
                        </Box>
                </div>
              )}
              {/* {!isProcessing && (
                <div className="stripeLoaderWrapper">
                  <Loader loaderColor="#4517ff" className="stripeLoader" />
                </div>
              )} */}

              {displayCouponField && (
                <>
                  {!couponSuccess && (
                    <Row className="rowCoupon">
                      <FormField
                        name="coupon"
                        label="Coupon"
                        type="text"
                        value={couponIDFieldValue ? couponIDFieldValue : ''}
                        onChange={ev => {
                          setcouponIDFieldValue(ev.target.value);
                        }}
                        placeholder="YOURCODE"
                        required={false}
                      />
                    </Row>
                  )}
                  
                  {!couponSuccess && (
                    <Row
                      className="rowCoupon rowCouponButton"
                      style={{ marginTop: '0.5rem' }}
                    >
                      <>
                        <Button
                          className="applyCoupon"
                          title="Apply coupon"
                          onClick={() => {
                            handleCheckCoupon();
                          }}
                        />
                      </>
                    </Row>
                  )}
                </>
              )}

              <Row
                className={cardNumberComplete ? 'rowCard active' : 'rowCard'}
                style={{
                  opacity: isProcessing ? 0.33 : 1,
                  border: !cardNumberComplete ? '1px solid #27E8A2' : '',
                }}
              >
                <CardElementContainer>
                  <CardElement
                    options={cardElementOpts}
                    onChange={handleCardDetailsChange}
                  />
                </CardElementContainer>
              </Row>

              {checkoutError && (
                <Row>
                  <Alertantd message={checkoutError} type="error" />
                </Row>
              )}

              <Row className="rowSubmitButton">
                {/* TIP always disable your submit button while processing payments */}
                {/* TODO: move the price somewhere else (not on button) */}
                <SubmitButton
                  cardNumberComplete={cardNumberComplete}
                  disabled={isProcessing || !stripe}
                  className={cardNumberComplete ? 'active' : 'disabled'}
                >
                  {isProcessing
                    ? 'Processing...'
                    : `Get your Remote Mentors Pass for $${Math.trunc(
                        getStripePriceAmount() * priceDiscountedRatio
                      )}`}
                </SubmitButton>
              </Row>

              {!couponSuccess && (
                <TextContainer style={{ opacity: isProcessing ? 0 : 1 }}>
                  <AnchorLink
                    className="smooth_scroll"
                    href="#header"
                    offset={81}
                  >
                    <TextContent
                      className="showCoupon"
                      onClick={() => {
                        setDisplayCouponField(!displayCouponField);
                        // Analytics.record('displayCouponField');
                      }}
                    >
                      {' '}
                      <>
                        Do you <u>have a coupon code</u>?
                      </>
                    </TextContent>
                  </AnchorLink>
                </TextContainer>
              )}
            </Box>
            {/* <Heading
              as="h4"
              className="descriptionStyle"
              content={data.description}
            /> */}
            <Row className="rowSecuredPayment">
              <Image src={stripeImage} alt="secured by Stripe" />
            </Row>

            <TextContainer className="stickBottom">
              <h6 className="extra-small">
                <span>
                  By subscribing to remotementors.io you agree to the{' '}
                </span>
                <a href="/tos" target="_blank">
                  terms and conditions
                </a>
                .
              </h6>
            </TextContainer>
          </Container>
          {process.env.GATSBY_LOCALHOST===true && (
            <Button
              title="Check user"
              onClick={() => {
                checkUserCognito();
              }}
              className=""
            />
          )}
        </BlockWrapper>
      </Box>
    </form>)}
      {invoiceUrl && (<> 
      <Box {...sectionWrapper} id="pricing_section">
        <BlockWrapper>
          <Container>
            <Box {...secTitleWrapper}>
              <header>
                <Heading as="h5" {...secText} content="⚠️PLEASE TRY AGAIN ⚠️" />
                <h2 id="header">It seems we could not process the payment this time</h2>
                <Heading as="h3" {...secHeading} content="Please give it another try directly on the Stripe invoice" />
              </header>
              
              <Row>
                <Alertantd message="Sorry for the inconvenience. We know this things can happen. Let's try again." type="error" />
              </Row>      

              <Row className="rowSubmitButton">
                <Link to={invoiceUrl} target="_blank">
                  <SubmitButton
                    disabled={false}
                      className="active"
                      cardNumberComplete={cardNumberComplete}
                    >
                    Click here to try again
                  </SubmitButton>
                </Link>
              </Row>
                
              <Row className="rowSecuredPayment">
                <Image src={stripeImage} alt="secured by Stripe" />
              </Row>
                
            </Box>
          </Container>
        </BlockWrapper>  
      </Box>
      </>)}
    </>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['2rem'],
    pb: '20px',
  },
  secTitleWrapper: {
    mb: ['30px', '40px'],
  },
  secText: {
    as: 'h5',
    display: 'block',
    textAlign: 'center',
    letterSpacing: '0.15em',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    width: '500px',
    maxWidth: '100%',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    color: '#0f2137',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: '#0f2137',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',

    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // bg: '#fff',
    // color: '#2aa275',
    // colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '15px',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // colors: 'primaryWithBg',
    minWidth: ['160px', '190px'],
    maxWidth: '100%',
    height: '48px',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    mb: '0',
  },
};

export default PricingSection;
