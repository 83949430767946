const isBrowser = typeof window !== `undefined`;

export const setStripeCouponId = stripeCouponId => {
  if (isBrowser) {
    window.localStorage.gatsbyStripeCouponId = JSON.stringify(stripeCouponId);
  }
};

export const getStripeCouponId = () => {
  if (isBrowser && window.localStorage.gatsbyStripeCouponId) {
    let stripeCouponId = JSON.parse(window.localStorage.gatsbyStripeCouponId);
    return stripeCouponId ? stripeCouponId : false;
  }
  return {};
};

export const setStripeCoupon = stripeCoupon => {
  if (isBrowser) {
    window.localStorage.gatsbyStripeCoupon = JSON.stringify(stripeCoupon);
  }
};

export const getStripeCoupon = () => {
  if (isBrowser && window.localStorage.gatsbyStripeCoupon) {
    let stripeCoupon = JSON.parse(window.localStorage.gatsbyStripeCoupon);
    return stripeCoupon ? stripeCoupon : {};
  }
  return {};
};

export const setStripePriceId = stripePriceId => {
  if (isBrowser) {
    window.localStorage.gatsbyStripePriceId = JSON.stringify(stripePriceId);
  }
};

export const getStripePriceId = () => {
  if (isBrowser && window.localStorage.gatsbyStripePriceId) {
    let stripePriceId = JSON.parse(window.localStorage.gatsbyStripePriceId);
    return stripePriceId ? stripePriceId : {};
  }
  return {};
};

export const setStripePriceAmount = stripePriceAmount => {
  if (isBrowser) {
    window.localStorage.gatsbyStripePriceAmount = JSON.stringify(
      stripePriceAmount
    );
  }
};

export const getStripePriceAmount = () => {
  if (isBrowser && window.localStorage.gatsbyStripePriceAmount) {
    let stripePriceAmount = JSON.parse(
      window.localStorage.gatsbyStripePriceAmount
    );
    return stripePriceAmount ? stripePriceAmount : {};
  }
  return {};
};
